import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-title pb-0" }
const _hoisted_2 = { class: "page-header" }
const _hoisted_3 = { class: "application-navbar" }
const _hoisted_4 = { class: "nav nav-tabs mb-0" }
const _hoisted_5 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mpx_icon = _resolveComponent("mpx-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t("pages.applications.title")), 1),
      _createElementVNode("h1", null, [
        _createVNode(_component_router_link, { to: '/dashboard/applications' }, {
          default: _withCtx(() => [
            _createVNode(_component_mpx_icon, { variant: 'arrow-left' })
          ]),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$store.state.application.hmacAuthApplication?.name), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setActiveTab('detail')))
            }, [
              _createVNode(_component_router_link, {
                to: '/dashboard/applications/' + _ctx.uuid,
                class: _normalizeClass(`nav-link no-decoration ${
                  _ctx.activeTabId == 'detail' ? 'active' : ''
                }`)
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Detail ")
                ])),
                _: 1
              }, 8, ["to", "class"])
            ]),
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setActiveTab('credentials')))
            }, [
              _createVNode(_component_router_link, {
                to: '/dashboard/applications/' + _ctx.uuid + '/credentials',
                class: _normalizeClass(`nav-link no-decoration ${
                  _ctx.activeTabId == 'credentials' ? 'active' : ''
                }`)
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Credentials ")
                ])),
                _: 1
              }, 8, ["to", "class"])
            ]),
            _createElementVNode("li", {
              class: "nav-item",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setActiveTab('advanced')))
            }, [
              _createVNode(_component_router_link, {
                to: '/dashboard/applications/' + _ctx.uuid + '/advanced',
                class: _normalizeClass(`nav-link no-decoration ${
                  _ctx.activeTabId == 'advanced' ? 'active' : ''
                }`)
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Advanced ")
                ])),
                _: 1
              }, 8, ["to", "class"])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_view)
    ])
  ]))
}